<template>
	<div class="rentQi">
		<a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 11 }">
<!--			<a-form-model-item label="节点名称" prop="OrgId">
				<a-select show-search allowClear placeholder="请选择" v-model="form.OrgId">
					<a-select-option v-for="item in departmentList" :key="item.value" :value="item.value">
						{{ item.title }}
					</a-select-option>
				</a-select>
			</a-form-model-item>-->
            <a-form-model-item label="节点名称" prop="OrgId">
                <a-tree-select
                    v-model="form.OrgId"
                    show-search
                    style="width: 100%"
                    :dropdown-style="{ maxHeight: '600px', overflow: 'auto' }"
                    placeholder="请选择中心"
                    allow-clear
                    tree-default-expand-all
                    @search="OrgSearch"
                >
                    <a-tree-select-node v-for="(topItem) in departmentList" :key="topItem.value" :value="topItem.value" :title="topItem.title">
                        <a-tree-select-node v-for="(secItem) in topItem.children"
                                            :key="secItem.value"
                                            :value="secItem.value"
                                            :title="secItem.title"
                                            >
                            <a-tree-select-node v-for="(thirdItem) in secItem.children"
                                                :key="thirdItem.value"
                                                :value="thirdItem.value"
                                                :title="thirdItem.title"
                                                >
                                <a-tree-select-node v-for="(fourdItem) in thirdItem.children"
                                                    :key="fourdItem.value"
                                                    :value="fourdItem.value"
                                                    :title="fourdItem.title"
                                >
                                </a-tree-select-node>
                            </a-tree-select-node>
                        </a-tree-select-node>
                    </a-tree-select-node>
                </a-tree-select>
            </a-form-model-item>
	 		<a-form-model-item label="有效期">
				<a-range-picker v-model="form.Effect" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss"
					:placeholder="['开始时间', '结束时间']" allowClear @change="dateChange"   />
			</a-form-model-item>
		     <a-form-model-item label="状态">
						<a-select placeholder="请选择" v-model="form.Status" allowClear>
							<a-select-option value="">全部</a-select-option>
							<a-select-option :value='1'>正常</a-select-option>
							<a-select-option :value='0'>停用</a-select-option>
						</a-select>
			</a-form-model-item>
                
		</a-form-model>
	</div>
</template>

<script>
   import   Dayjs  from 'dayjs';
 
	export default {
		name: 'rentQi',

		data() {
			return {

				form: {
					OrgId: '',
				 
		 			Status: '',
                    Effect:'',
                    NodeId:'',
                    StartTime:'',
                    EndTime:'',
				}, 
				rules: {
					OrgId: [{
						OrgId: true,
						message: '请输选择中心',
						trigger: 'blur'
					}] 

				},
                //  value1: ref(),
                //  value2: ref(),
                //  ranges: {
                //     Today: [dayjs(), dayjs()],
                //     'This Month': [dayjs(), dayjs().endOf('month')],
                // },
			 	userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                departmentList: []
		 
			}
		},
		props: {
			formData: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		created() {

			// 如果是编辑数据，修正表单数据类型
			if (this.formData.Id) {

				for (const key in this.form) {
					if (typeof this.form[key] === 'boolean') {
						this.form[key] = !!this.formData[key]
					} else if (key === 'SchoolId' || key === 'ApplyTo') {
						this.form[key] = this.formData[key] ? this.formData[key].split(',') : []

					} else if (key === 'Effect') {
						this.form.Effect = [this.formData.StartTime, this.formData.EndTime]
                        this.form.StartTime = this.formData.StartTime
                         this.form.EndTime = this.formData.EndTime
					} else {
						this.form[key] = this.formData[key]
					}
				}
			}
			console.log(this.form)
			 
			 this.getDepartmentList()
	 
		},
		methods: {
			handleChange(key) {
				if (this.form[key].some(item => item === 'all')) {
					this.form[key] = ['all']
					this.$refs[key].blur()
				}
			},
			//  
		 	getDepartmentList() {
				this.$axios.get(5836120, {}, res => {
					if (res.data.code == 1) {
						this.departmentList = res.data.data.schoollist
					}
				})
			},
			// 日期控件值变化
			dateChange(val) {
				if (val[0]) {
					this.form.StartTime = val[0]
					this.form.EndTime = val[1]
				} else {
					this.form.StartTime = ''
					this.form.EndTime = ''
				}
			},
			handleChange(key, val) {
				if (this.form[key].some(item => item === 'all')) {
					this.form[key] = ['all']
					this.$refs[key].blur()
				}
			},
			onSubmit() {
				return new Promise((resolve, reject) => {
					this.$refs.ruleForm.validate(valid => {
						if (valid) {
							resolve(this.form)
						} else {
							reject(false)
						}
					})
				})
			},
            OrgSearch(){

            }

		}
	}
</script>

<style scoped lang="less">
	.rentQi {
		background: #ffffff;
		margin-top: 15px;
		min-height: 800px;
		padding: 15px;
	}
</style>
