<template>
	<div class="hdTicket">
		<div class="page-header flex">
			<span style="font-size: 16px;">节点管理</span>
			<div>
				<div class="screen-btn ant-menu-item-selected" @click="_makeTodo('add')">
					<a-icon type="plus-circle" />
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="page-main">
			<div class="screen-list">
				<a-form-model layout="inline" :model="souForm">
					<a-form-model-item label="关键字">
						<a-input placeholder="请输入关键字" v-model="souForm.NodeName" allowClear />
					</a-form-model-item>
				 
				</a-form-model>
				<div class="form-btn">
					<a-button @click="_resetBtn">重置条件</a-button>
					<a-button type="primary" @click="_searchBtn">开始查询</a-button>
				</div>
			</div>
			<div class="todo-content">
				<a-table     :columns="columns" :dataSource="infoData" :pagination="false" bordered v-if="infoData">
					<div slot="SetCount" slot-scope="text, row">
					 	{{text}} <a-icon type="bars"  @click="_dataListAction(true,row)" class="icon-btn"></a-icon> 
					 
					</div>
					<div slot="Operation" slot-scope="text, row">
			 			<a-icon type="form" @click="_makeTodo(row)" class="icon-btn" />
						 
						<a-popconfirm title="您确定要删除吗?" @confirm="_delTableRow(row)" okText="确定" cancelText="取消">
							<a-icon type="delete" class="icon-btn" v-if="row.DelBoolean==0" />
						</a-popconfirm>
						 
					</div>
				</a-table>

				<div style="margin: 20px 0; text-align: right;">
					<a-pagination showQuickJumper :defaultCurrent="1" :total="pageTotal" @change="_PageChange" />
				</div>
			</div>

		
			<a-drawer :title="isEdit ? '节点信息' : '添加节点'" width="1000" :closable="false" :visible="OperateNodeVisible"
				@close="OperateNodeClose">
			 
					<addOperateNode ref="addOperateNode" v-if="OperateNodeVisible" :formData="formData"
						@visible="OperateNodeClose"></addOperateNode>
		 
			  <div class="drawer-bottom">
                    <a-button  style="width: 120px; text-align: center; margin-right: 20px;"  @click="onMakeClose"
                        >取消
                    </a-button>
                    <a-button type="primary"    style="width: 120px; text-align: center;" @click="_saveMakeTodo">确定</a-button>
			  </div>
			</a-drawer>

		<a-drawer v-if="dataListSatus" title="节点详情列表" width="1200" :closable="false" :visible="dataListSatus" @close="_dataListAction(false)">
            <operateNodeSetList :FieldModelName="FieldModelName" :FieldModelId="FieldModelId" @visible="_dataListAction(false)"></operateNodeSetList>
        </a-drawer>
		</div>
	</div>
</template>

<script>
 	import addOperateNode from 'pages/OperateNode/addOperateNode'
	import operateNodeSetList from 'pages/OperateNode/operateNodeSetList'
	const columns = [
   
	    {
			title: '名称',
			dataIndex: 'NodeName',
			align: 'center'
		},
		{
			title: '节点值',
			dataIndex: 'NodeValue',
			align: 'center'
		},
		{
			title: '备注',
			dataIndex: 'Remark',
			align: 'center'
		},
		{
			title: '应用设置',
			dataIndex: 'SetCount',
			align: 'center',
			scopedSlots: {
				customRender: 'SetCount'
			}
		},
		{
			title: '操作',
			dataIndex: 'Operation',
			align: 'center',
			scopedSlots: {
				customRender: 'Operation'
			}
		}
	]
	export default {
		name: 'hdTicket',
		components: {
			addOperateNode,operateNodeSetList
		},
		data() {
			return {
				columns: columns,
				pageTotal: 0,
				page: 1,
				souForm: {
					NodeName: undefined, //关键字
				 
				},
				OperateNodeVisible: false,
				infoData: [],
				isEdit: false,
				formData: {},
				dataListSatus:false,
				FieldModelId:'',
				FieldModelName:''
                // bookSelectedRowKeys:[],
                // bookSelectedRow:[]
			}
		},
		created() {
		 
			this._info()
		},
		methods: {
		   _dataListAction(val,data){
                this.dataListSatus = val;
                let list = data  || '';
                if(list){
                    this.FieldModelId = list.Id;
                    this.FieldModelName = list.NodeName;
                }
			 	this._info()

            },				// 关闭表单
			OperateNodeClose(key) {
				if (key === 'actClose') {
					this.page = 1
					this._info()
				}
				this.OperateNodeVisible = false
			},
            // bookOnChange(selectedRowKeys, selectedRows) {
			// 	//选中的数据的key
			// 	this.bookSelectedRowKeys = selectedRowKeys;
			// 	this.bookSelectedRow = selectedRows;
			// },
			onMakeClose() {
				this.OperateNodeVisible = false
			},
			// 显示表单
			_makeTodo(row) {
				if (row !== 'add') {
				 	this.OperateNodeVisible = true
					this.isEdit = true
					this.formData = {
						...row
					}
				} else {
					this.OperateNodeVisible = true
					this.isEdit = false
					this.formData = {}
				}
			
			},
			// 检索
			_searchBtn() {
				this.page = 1
				this._info()
			},
			// 重置
			_resetBtn() {
				this.souForm.NodeName = undefined
			 
			},
			// 删除
			_delTableRow(index) {
				this.$axios.post(
					80830003, {
						Id: index.Id
					},
					res => {
						if (res.data.code == 1) {
							this.$message.success(res.data.msg)
						} else {
							this.$message.success(res.data.msg)
						}
						this._info()
					}
				)
			},
		
			// 分页事件
			_PageChange(page) {
				this.page = page
				this._info()
			},
		 
			// 获取表格内容
			_info() {
				this.$message.loading('加载中...')
				// let department = this.souForm.department.join()
				let data = {
					NodeName: this.souForm.NodeName,
	 
					Page: this.page,
					PageSize: 10
				}
				this.$axios.get(80830001, data, res => {
					if (res.data.code == 1) {
						this.infoData = res.data.data
						this.pageTotal = res.data.count
						this.page = this.page
					} else {
						this.pageTotal = 1
						this.infoData = []
					}
					this.$message.destroy()
				})
			}, 
			 
			async _saveMakeTodo() {
				let result1
				try {
					result1 = await this.$refs.addOperateNode.onSubmit()
				} catch (error) {
					// console.error(error)
				}


				// console.log(result1)
				if (result1) {
					let form = {
						...result1
					}
					for (const key in form) {
						if (typeof form[key] === 'boolean') {
							form[key] = +form[key]
						}
						if (typeof form[key] === 'object') {
							if (key === 'ProductList') {
								form[key] = form[key].join()
								if (form[key] === 'undefined') {
									form[key] = ''
								}
							}
						}
					}
					// 如果是编辑
					if (this.formData.Id) {
						form.Id = this.formData.Id
						
					}
					form.CreateUserId = JSON.parse(sessionStorage.getItem('userInfo')).uid
					this.$axios.post(80830002, {
						...form
					}, res => {
						if (res.data.code == 1) {
							this.$message.success(res.data.msg)
							this.$emit('visible', 'actClose')
							this._info()
						} else {
							this.$message.error(res.data.msg)
						}

					})
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.hdTicket {
		width: 100%;
		background: #fff;
		box-sizing: border-box;
		font-size: 14px;

		.page-header {
			height: 55px;
			line-height: 55px;
			padding: 10px 20px;
			border-bottom: 1px solid #e1e2e3;

			.screen-btn {
				width: 100px;
				display: inline-block;
				font-size: 12px;
				margin-left: 20px;
				border-width: 1px;
				border-style: solid;
				border-radius: 20px;
				padding: 9px 0;
				line-height: 1;
				text-align: center;
				background: #fff !important;
				cursor: pointer;

				i {
					margin-right: 4px;
				}
			}
		}

		.page-main {
			padding: 20px;

			.screen-list {
				margin: 0 0 15px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				:deep(.ant-select-selection--single),
      :deep(.ant-form-item-control) {
        min-width: 150px;
      }

				.form-btn {
					button+button {
						margin-left: 10px;
					}
				}
			}
		}

		.icon-btn {
			cursor: pointer;
			margin-left: 10px;
			font-size: 18px;
		}
		 .drawer-bottom {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            border-top: 1px solid #e9e9e9;
            padding: 10px 16px;
            background: #fff;
            }
			
	}
</style>
