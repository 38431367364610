<template>
	<div class="rentQi">
		<a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 11 }">
			<a-form-model-item label="节点名称" prop="NodeName">
				<a-input v-model="form.NodeName" allowClear />
			</a-form-model-item>
	 		<a-form-model-item label="节点值" prop="NodeValue">
				<a-input v-model="form.NodeValue" allowClear />
			</a-form-model-item>
			<a-form-model-item label="备注">
				<a-textarea v-model="form.Remark" :auto-size="{ minRows: 3, maxRows: 5 }" placeholder="备注" />
			</a-form-model-item>


		</a-form-model>
	</div>
</template>

<script>
	export default {
		name: 'rentQi',

		data() {
			return {

				form: {
					NodeName: '',
					NodeValue: '',
		 			Remark: '',
				},
				isShowDepartment: false,
				rules: {
					NodeName: [{
						required: true,
						message: '请输入节点名称',
						trigger: 'blur'
					}],
					NodeValue: [{
						required: true,
						message: '请输入节点值',
						trigger: 'blur'
					}]


				},
				userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
		 
			}
		},
		props: {
			formData: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		created() {

			// 如果是编辑数据，修正表单数据类型
			if (this.formData.Id) {

				for (const key in this.form) {
					if (typeof this.form[key] === 'boolean') {
						this.form[key] = !!this.formData[key]
					} else if (key === 'SchoolId' || key === 'ApplyTo') {
						this.form[key] = this.formData[key] ? this.formData[key].split(',') : []

					} else if (key === 'Effect') {
						this.form.Effect = [this.formData.UsableStartDate, this.formData.UsableEndDate]
					} else {
						this.form[key] = this.formData[key]
					}
				}
			}
			console.log(this.form)
			//课卷使用时间
			 
	 
		},
		methods: {
			handleChange(key) {
				if (this.form[key].some(item => item === 'all')) {
					this.form[key] = ['all']
					this.$refs[key].blur()
				}
			},
			//  
		 
			// 日期控件值变化
			dateChange(val) {
				if (val[0]) {
					this.form.UsableStartDate = val[0]
					this.form.UsableEndDate = val[1]
				} else {
					this.form.UsableStartDate = ''
					this.form.UsableEndDate = ''
				}
			},
			handleChange(key, val) {
				if (this.form[key].some(item => item === 'all')) {
					this.form[key] = ['all']
					this.$refs[key].blur()
				}
			},
			onSubmit() {
				return new Promise((resolve, reject) => {
					this.$refs.ruleForm.validate(valid => {
						if (valid) {
							resolve(this.form)
						} else {
							reject(false)
						}
					})
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.rentQi {
		background: #ffffff;
		margin-top: 15px;
		min-height: 800px;
		padding: 15px;
	}
</style>
